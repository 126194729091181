<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="7"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="clientData.logo"
            :text="avatarText(clientData.name)"
            :variant="clientData.logo ? `white` : `light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ clientData.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                id="view-dashboard-btn"
                :to="`/client/${clientData.slug}/dashboard`"
                variant="primary"
              >
                View Analytics Dashboard
                <feather-icon size="20" icon="PieChartIcon"></feather-icon>
              </b-button>
              <!-- <b-button variant="outline-danger" class="ml-1">
                Delete
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="UsersIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{
                  getFormatedValue(
                    clientData.analytics.google.users.value,
                    clientData.analytics.google.users.type
                  )
                }}
              </h5>
              <small>Users</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DatabaseIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{
                  getFormatedValue(
                    clientData.analytics.google.sessions.value,
                    clientData.analytics.google.sessions.type
                  )
                }}
              </h5>
              <small>Sessions</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{
                  getFormatedValue(
                    clientData.analytics.google.bounceRate.value,
                    clientData.analytics.google.bounceRate.type
                  )
                }}
              </h5>
              <small>Bounce Rate</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="ClockIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{
                  getFormatedValue(
                    clientData.analytics.google.avgSessionDuration.value,
                    clientData.analytics.google.avgSessionDuration.type
                  )
                }}
              </h5>
              <small>Session Duration</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="5">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Slug</span>
            </th>
            <td class="pb-50">
              {{ clientData.slug }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-${resolveClientStatusVariant(
                  clientData.isActive
                )}`"
                class="text-capitalize"
              >
                {{ clientData.isActive ? "Active" : "Inactive" }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Google</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                v-if="clientData.integrations && clientData.integrations.google"
                pill
                :variant="`light-${resolveIntegrationVariant(
                  clientData.integrations,
                  'google'
                )}`"
                class="text-capitalize"
              >
                {{
                  clientData.integrations.google ? "Connected" : "Not Connected"
                }}
              </b-badge>
              <b-button
                @click="googleAnalyticsConnect()"
                size="sm"
                :disabled="!accountSelector"
                variant="primary"
                v-else
                >Connect Google</b-button
              >
              <b-button
                :disabled="!accountSelector"
                v-if="clientData.integrations && clientData.integrations.google"
                @click="fetchAnalyticsAccounts"
                class="mr-1"
                variant="primary"
                size="sm"
                ><b-spinner v-if="isFetchingAccounts" small />
                {{ accountSelectorText }}
              </b-button>
              <b-badge
                v-if="
                  clientData.integrations &&
                  clientData.integrations.google &&
                  clientData.googleViewProfile
                "
                pill
                variant="warning"
                >{{ clientData.googleViewProfile }}</b-badge
              >
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Meta</span>
            </th>
            <td class="pb-50">
              <b-badge
                v-if="
                  clientData.integrations && clientData.integrations.facebook
                "
                pill
                :variant="`light-${resolveIntegrationVariant(
                  clientData.integrations,
                  'facebook'
                )}`"
                class="text-capitalize"
              >
                {{
                  clientData.integrations.facebook
                    ? "Connected"
                    : "Not Connected"
                }}
              </b-badge>
              <b-button disabled size="sm" variant="primary" v-else
                >Connect Meta</b-button
              >
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import millify from "millify";
import moment from "moment";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BSpinner,
  },
  props: {
    clientData: {
      integrations: {
        google: null,
        facebook: null,
      },
      analytics: {
        google: {
          users: {
            value: {
              type: String,
              default: 0,
            },
            type: {
              type: String,
              default: null,
            },
          },
          sessions: {
            value: {
              type: String,
              default: 0,
            },
            type: {
              type: String,
              default: null,
            },
          },
          bounceRate: {
            value: {
              type: String,
              default: 0,
            },
            type: {
              type: String,
              default: null,
            },
          },
          avgSessionDuration: {
            value: {
              type: String,
              default: 0,
            },
            type: {
              type: String,
              default: null,
            },
          },
        },
      },
    },
    isFetchingAccounts: {
      type: Boolean,
      default: false,
    },
    accountSelector: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
  methods: {
    resolveClientStatusVariant(status) {
      return status ? "success" : "danger";
    },
    resolveIntegrationVariant(integrations, provider) {
      return integrations[provider] ? "success" : "danger";
    },
    googleAnalyticsConnect() {
      this.$emit("googleAnalyticsConnect", true);
    },
    fetchAnalyticsAccounts() {
      this.$emit("fetchAnalyticsAccounts", true);
    },
    getFormatedValue(value, type) {
      if (type === "INTEGER") {
        return millify(value);
      } else if (type === "PERCENT") {
        value = parseFloat(value);
        return value.toFixed(2) + "%";
      } else if (type === "TIME") {
        var dur = moment.duration(parseInt(value, 10), "seconds");
        var minutes = dur.minutes();
        var seconds = dur.seconds();
        return `${minutes}m ${seconds}s`;
      } else {
        return value;
      }
    },
  },
  computed: {
    accountSelectorText() {
      if (
        this.clientData.integrations &&
        this.clientData.integrations.google &&
        !this.clientData.googleViewID
      ) {
        return "Select Account";
      } else if (
        this.clientData.integrations &&
        !this.clientData.integrations.google
      ) {
        return "";
      } else if (
        this.clientData.integrations &&
        this.clientData.integrations.google &&
        this.clientData.googleViewID
      ) {
        return "Reselect Account";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#view-dashboard-btn {
  svg {
    vertical-align: middle;
  }
}
</style>
