<template>
  <div>
    <!-- :show="!googleMetricsData.length && !facebookMetricsData.length" -->
    <b-overlay
      id="overlay-background"
      :show="false"
      variant="white"
      opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <b-row>
        <b-col cols="12" md="8">
          <b-card>
            <b-tabs content-class="pt-1" fill>
              <b-tab :disabled="!googleIntegration" active>
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>Google Analytics</span>
                </template>
                <b-card-text>
                  <b-input-group class="input-group-merge mb-2">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="search"
                      size="lg"
                      placeholder="Search"
                    />
                  </b-input-group>
                  <app-collapse type="border">
                    <app-collapse-item
                      v-for="(data, index) in googleMetricsData"
                      :key="data._id"
                      :title="data._id"
                    >
                      <b-form-group label="Metrics:">
                        <b-form-checkbox-group
                          :id="`checkbox-group-metrics-${index}`"
                          v-model="selectedGoogleMetricsDimension.metrics"
                          :options="getValidOptions(data.metrics)"
                          name="metrics"
                          class="demo-inline-spacing"
                          value-field="item"
                          text-field="name"
                        >
                        </b-form-checkbox-group>
                      </b-form-group>

                      <!-- <b-form-group label="Dimensions:">
                        <b-form-checkbox-group
                          :id="`checkbox-group-dimension-${index}`"
                          v-model="selectedGoogleMetricsDimension.dimensions"
                          :options="
                            getValidOptions(
                              data.dimenions ? data.dimenions : []
                            )
                          "
                          name="dimensions"
                          class="demo-inline-spacing"
                          value-field="item"
                          text-field="name"
                        >
                        </b-form-checkbox-group>
                      </b-form-group> -->
                    </app-collapse-item>
                  </app-collapse>

                  <!-- <div class="text-center">
                    <b-button
                      @click="saveGoogleMetricDimensionData"
                      class="mt-2"
                      variant="primary"
                    >
                      <feather-icon icon="SaveIcon" />
                      Save
                    </b-button>
                  </div> -->
                </b-card-text>
              </b-tab>
              <b-tab :disabled="!facebookIntegration" title="Meta">
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>Meta</span>
                </template>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card id="sticky-card">
            <b-tabs content-class="pt-1" fill>
              <b-tab :disabled="!googleIntegration" active>
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>Google Analytics</span>
                </template>
                <b-card-text>
                  <h5>Selected Metrics</h5>
                  <div>
                    <b-badge
                      v-for="(
                        metric, index
                      ) in selectedGoogleMetricsDimension.metrics"
                      :key="metric"
                      pill
                      variant="light-dark"
                      :class="index > 0 ? 'mt-1' : ''"
                    >
                      {{ metric }}
                      <b-button
                        @click="removeMetric(metric)"
                        class="btn-icon btn-flat-light rounded-circle"
                        variant="flat-danger"
                        size="sm"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-badge>
                  </div>
                  <!-- <h5 class="mt-3">Selected Dimensions</h5>
                  <div>
                    <b-badge
                      v-for="(
                        dimension, index
                      ) in selectedGoogleMetricsDimension.dimensions"
                      :key="dimension"
                      pill
                      variant="light-dark"
                      :class="index > 0 ? 'mt-1' : ''"
                    >
                      {{ dimension }}
                      <b-button
                        @click="removeDimension(dimension)"
                        class="btn-icon btn-flat-light rounded-circle"
                        variant="flat-danger"
                        size="sm"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-badge>
                  </div> -->
                </b-card-text>
              </b-tab>
              <b-tab :disabled="!facebookIntegration" title="Meta">
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>Meta</span>
                </template>
              </b-tab>
            </b-tabs>

            <div class="text-center">
              <b-button
                @click="saveGoogleMetricDimensionData"
                class="mt-2"
                variant="primary"
              >
                <feather-icon icon="SaveIcon" />
                Save
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BOverlay,
  BFormInvalidFeedback,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  props: {
    googleMetricsData: {
      type: Array,
      required: true,
    },
    facebookMetricsData: {
      type: Array,
      required: true,
    },
    googleIntegration: {
      type: Boolean,
      required: true,
    },
    facebookIntegration: {
      type: Boolean,
      required: true,
    },
    googleAdsIntegration: {
      type: Boolean,
      required: true,
    },
    selectedGoogleMetricsDimension: {
      type: Object,
      required: true,
    },
  },
  components: {
    BTabs,
    BTab,
    BCardText,
    BCard,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BFormInvalidFeedback,
    BBadge,
  },
  data() {
    return {
      google: {
        metrics: [],
        dimensions: [],
      },
      search: "",
    };
  },
  computed: {},
  methods: {
    getValidOptions(data) {
      const validData = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item) {
          validData.push({
            item: item.id,
            name: `${item.id} - ${item.uiName}`,
          });
        }
      }
      return validData;
    },
    saveGoogleMetricDimensionData() {
      this.$emit(
        "saveGoogleMetricDimensionData",
        this.selectedGoogleMetricsDimension
      );
    },
    removeMetric(metric) {
      const metricIndex = this.selectedGoogleMetricsDimension.metrics.findIndex(
        (metricItem) => {
          return metricItem == metric;
        }
      );
      if (metricIndex > -1) {
        this.selectedGoogleMetricsDimension.metrics.splice(metricIndex, 1);
        this.saveGoogleMetricDimensionData();
        return;
      }
      return;
    },
    removeDimension(dimenion) {
      const dimensionIndex =
        this.selectedGoogleMetricsDimension.dimensions.findIndex(
          (dimenionItem) => {
            return dimenionItem == dimenion;
          }
        );
      if (dimensionIndex > -1) {
        this.selectedGoogleMetricsDimension.dimensions.splice(
          dimensionIndex,
          1
        );
        this.saveGoogleMetricDimensionData();
        return;
      }
      return;
    },
  },
  watch: {
    search(searchVal) {
      this.$emit("googleMetricSearch", searchVal);
    },
  },
};
</script>

<style scoped>
#sticky-card {
  position: sticky;
  top: 100px;
}
</style>