<template>
  <div>
    <b-row v-if="client" class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0 text-white">
              <b-avatar
                class="mr-1"
                size="32"
                :src="client.logo"
                :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `light-primary`"
              />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                  class="text-white"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-alert :show="!client" variant="danger">
      <h4 class="alert-heading">Error fetching Client data</h4>
      <div class="alert-body">
        {{ errorMessage }}. Check
        <b-link class="alert-link" :to="{ name: 'client-list' }">
          Clients List
        </b-link>
        for other clients.
      </div>
    </b-alert>
    <b-overlay :show="isFetchingAnalytics" rounded="sm">
      <div v-if="client">
        <b-row class="match-height">
          <b-col cols="4">
            <date-selector @fetchNewData="updateDateRange" />
          </b-col>
          <b-col cols="8">
            <b-card no-body class="card-statistics">
              <b-card-body class="statistics-body">
                <b-row>
                  <b-col
                    v-for="staticData in data.staticCardData"
                    :key="staticData.name"
                    cols="3"
                  >
                    <div class="text-center mb-1">
                      <b-avatar
                        size="48"
                        :variant="getIconVariant(staticData.name).variant"
                      >
                        <feather-icon
                          size="24"
                          :icon="getIconVariant(staticData.name).icon"
                        />
                      </b-avatar>
                    </div>
                    <b-media class="text-center" no-body>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{
                            getFormatedValue(staticData.value, staticData.type)
                          }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ getFormattedName(staticData.name) }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col cols="4">
            <b-card>
              <b-card-title>
                <h3 class="app-title sm">{{ userTypes.title }}</h3>
              </b-card-title>
              <app-echart-doughnut
                :options="userTypes.options"
                :series="userTypes.series"
              />
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card>
              <b-card-title>
                <h3 class="app-title sm">Sessions By Country (Top 10)</h3>
              </b-card-title>
              <chartjs-component-horizontal-bar-chart
                :height="400"
                :data="sessionsByCounty"
                :options="chartjsData.horizontalBarChart.options"
              />
            </b-card>
          </b-col>
          <b-col cols="4">
            <b-card>
              <b-card-title>
                <h3 class="app-title sm">Sessions by de vice</h3>
              </b-card-title>
              <app-echart-doughnut
                :options="sessionsByDevice.options"
                :series="sessionsByDevice.series"
              />
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BBadge,
  BButton,
  BTable,
  BSpinner,
  BFormGroup,
  BFormInput,
  BCardBody,
  BMedia,
  BMediaBody,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ClientDetail from "./UserViewUserInfoCard.vue";
import MetricDimensionSelector from "./MetricsDimensionSelector.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import millify from "millify";
import moment from "moment";
import LineChart from "../Charts/ChartjsLineChart.vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DateSelector from "./DateSelector.vue";
import ChartjsComponentHorizontalBarChart from "./charts-components/ChartjsComponentHorizontalBarChart.vue";
import chartjsData from "./chartjsData";

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      client: {
        integrations: {
          google: null,
          facebook: null,
        },
      },
      errorMessage: "",
      isFetchingAccounts: false,
      dateRange: [],
      isFetchingAnalytics: true,
      data: {
        userTypes: {},
        sessionDevices: {},
        sessionCounties: [],
        pagePathVisits: {},
        staticCardData: [],
      },
      chartjsData,
    };
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    ClientDetail,
    MetricDimensionSelector,
    ToastificationContent,
    BCard,
    BBadge,
    BButton,
    flatPickr,
    BTable,
    BSpinner,
    LineChart,
    BFormGroup,
    BFormInput,
    DateSelector,
    BCardBody,
    BMedia,
    BMediaBody,
    BCardText,
    BOverlay,
    AppEchartDoughnut,
    ChartjsComponentHorizontalBarChart,
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    resolveClientStatusVariant(status) {
      return status ? "success" : "danger";
    },
    resolveIntegrationVariant(integrations, provider) {
      return integrations[provider] ? "success" : "danger";
    },
    getData(startDate, endDate) {
      const dates = this.dateRange;
      if (!startDate || !endDate) {
        startDate = dates[0];
        endDate = dates[1];
      }
      const clientID = this.client._id;
      this.isFetchingAnalytics = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/analytics/get-data/google`,
          {
            clientID,
            startDate,
            endDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAnalytics = false;
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.isFetchingAnalytics = false;
        });
    },
    getClient() {
      const slug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = response.data.client;
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: `/client/${response.data.client.slug}`,
          });
          this.breadcrumbs.push({
            text: "Analytics Dashboard",
            to: "/client",
            active: true,
          });
          this.isPublicDashboard = response.data.client.publicDashboard;
          this.publicDashboardURL = `${process.env.VUE_APP_URL}/client/${response.data.client.slug}/dashboard/public`;
          this.getData();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
          this.client = null;
          this.errorMessage = error.response.data.error;
        });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    getFormatedValue(value, type) {
      if (type === "INTEGER") {
        return millify(value);
      } else if (type === "PERCENT") {
        value = parseFloat(value);
        return value.toFixed(2) + "%";
      } else if (type === "TIME") {
        var dur = moment.duration(parseInt(value, 10), "seconds");
        var minutes = dur.minutes();
        var seconds = dur.seconds();
        return `${minutes}m ${seconds}s`;
      } else {
        return value;
      }
    },
    getFormattedName(name) {
      if (name === "ga:sessions") {
        return "Sessions";
      } else if (name === "ga:pageviews") {
        return "Page Views";
      } else if (name === "ga:avgSessionDuration") {
        return "Avg. Session Duration";
      } else if (name === "ga:bounceRate") {
        return "Bounce Rate";
      }
    },
    getIconVariant(name) {
      if (name === "ga:sessions") {
        return {
          icon: "SmartphoneIcon",
          variant: "light-warning",
        };
      } else if (name === "ga:pageviews") {
        return {
          icon: "FileIcon",
          variant: "light-info",
        };
      } else if (name === "ga:avgSessionDuration") {
        return {
          icon: "ClockIcon",
          variant: "light-pink",
        };
      } else if (name === "ga:bounceRate") {
        return {
          icon: "TrendingDownIcon",
          variant: "light-danger",
        };
      }
    },
    updateDateRange(startDate, endDate) {
      this.getData(startDate, endDate);
    },
  },
  mounted() {
    this.getClient();
    const startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    const endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.dateRange = [startDate, endDate];
  },
  computed: {
    userTypes() {
      let userTypes = this.data.userTypes;
      let types = Object.keys(userTypes);
      let values = [];
      for (const type in userTypes) {
        if (Object.hasOwnProperty.call(userTypes, type)) {
          let userTypeData = userTypes[type];
          userTypeData = userTypeData.length ? userTypeData[0] : null;
          values.push({
            value: userTypeData.value,
            name: userTypeData.dimensionKey,
          });
        }
      }
      return {
        title: types.join(" vs "),
        series: [
          {
            name: "User Types",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: values,
          },
        ],
        options: {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },
          legend: {
            orient: "horizontal",
            left: "center",
          },
        },
      };
    },
    sessionsByCounty() {
      let sessionsData = this.data.sessionCounties;
      if (sessionsData.length) {
        let top10countries = sessionsData.slice(0, 10);
        let labels = [];
        let values = [];
        for (let i = 0; i < top10countries.length; i++) {
          const country = top10countries[i];
          labels.push(country.dimensionKey);
          values.push(country.value);
        }

        let data = {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: "#4D21FF",
              borderColor: "transparent",
              barThickness: 15,
            },
          ],
        };
        return data;
      } else {
        return null;
      }
    },
    sessionsByDevice() {
      let sessionsData = this.data.sessionDevices;
      let devices = [];
      let values = [];
      for (const key in sessionsData) {
        if (Object.hasOwnProperty.call(sessionsData, key)) {
          let deviceData = sessionsData[key];
          deviceData = deviceData.length ? deviceData[0] : null;
          if (deviceData.dimensionKey) {
            let deviceType =
              deviceData.dimensionKey.charAt(0).toUpperCase() +
              deviceData.dimensionKey.slice(1);
            values.push({
              value: deviceData.value,
              name: deviceType,
            });
            devices.push(deviceType);
          }
        }
      }
      return {
        series: [
          {
            name: "Sessions by Device",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            radius: ["40%", "70%"],
            labelLine: {
              show: false,
            },
            data: values,
          },
        ],
        options: {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },
          legend: {
            orient: "horizontal",
            left: "center",
          },
        },
      };
    },
  },
};
</script>

<style scoped>
svg.calendarIcon {
  height: 30px;
  width: 30px;
}


.breadcrumb-container  a{
  color: antiquewhite !important;
}

</style>