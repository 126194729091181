<template>
  <e-charts
    ref="line"
    autoresize
    :options="chartData"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "./theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    series: {
      type: Array,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    chartData() {
      let data = {
        ...this.options,
        series: this.series,
      };
      return data;
    },
  },
};
</script>

<style>
.echarts {
  width: 100%;
}
</style>