<template>
  <b-card>
    <p class="app-title my-2 xs">Showing Data for Last 7 days</p>
    <date-range-picker
      ref="picker"
      opens="right"
      :locale-data="{ firstDay: 1, format: 'yyyy-dd-mm' }"
      :showWeekNumbers="false"
      :showDropdowns="true"
      :autoApply="true"
      v-model="dateRange"
      @update="updateValues"
      :dateFormat="dateFormat"
      control-container-class="picker-controller"
      :ranges="dateRanges"
      :auto-apply="false"
      :readonly="false"
    >
      <template v-slot:input="picker">
        <feather-icon icon="CalendarIcon" size="16" class="mr-1"></feather-icon>
        {{ formatDateDisplay(picker.startDate) }} -
        {{ formatDateDisplay(picker.endDate) }}
      </template>
    </date-range-picker>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BLink, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    vSelect,
    BCardBody,
    DateRangePicker,
  },
  props: {},
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
      };

      return ranges;
    },
  },
  data() {
    return {
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      opens: true,
      dateRange: {
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let endDate = moment(values.endDate).format("YYYY-MM-DD");
      this.$emit("fetchNewData", startDate, endDate);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("YYYY-MM-DD");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "YYYY-MM-DD"
      );

      if (!classes.disabled) {
        classes.disabled = d1 === d2;
      }
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.comparison-dashboard-link {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.reportrange-text {
  display: flex;
  align-items: center;
}

.picker-controller {
  background-color: #f5f6fa;
  padding: 8px;
  margin-top: 6px;
}
</style>
 